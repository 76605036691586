require(['jquery'], function($) {
	if ( $('.product-search-container').length ) {
	// Sidebar customization for pages that do not have child categories 
	  const sidebarWrapper = $('.sidebar-wrapper .mmenu-target-categories');
	  const catSidebar = $('.sidebar-category');
	  const catTitle = 'Categories: ' + $(".module-sub-categories .active.parent > a").text().trim();

	  const catSiblings = $(".module-sub-categories .active .page-item");
	  console.log("sidebar len"  + $('.sidebar-category').length);
	  if (catSiblings.length && $('.sidebar-category').length == 0) {
	  	      console.log("yesssss");
	          let siblingHtml = '';
	          $(catSiblings).each(function(){
	              const anchor = $(this).find('a');
	              const href = anchor.attr('href');
	              const title = anchor.text().trim();
	              // add check here to see if the current url is the same URL to be inserted. If so, then remove URL change color to cymot orange
	              console.log("TESTINGGG: " + $('.module-sub-categories .page-item .active').text().trim());
	              console.log("TESTINGGG: " + title);
	              if ( $('.module-sub-categories .page-item .active').text().trim() == title ) {
	              	var listItem = "<li style='color:#f09638;font-weight:700;padding:0px 10px !important;cursor:not-allowed;'>"+title+"</li>";
	              } else {
	              	var listItem = "<li><a href="+href+">"+title+"</a></li>";
	              }
				  siblingHtml += listItem;
	          });
	
	          $(sidebarWrapper).append('<div class="sidebar in-sidebar sidebar-category"><div class="block block-vertnav"><div class="block-title"><strong><span>'+catTitle+'</span></strong></div><div class="block-content"><ul class=" accordion accordion-style1 vertnav vertnav-side clearer">'+siblingHtml+'</ul></div></div></div>');
	  }
	}
	// Hide banners on home page
	if ($('.isPublic').length) {
		$('.b2b-banner').hide();
		$('.b2b-hotdeals').hide();	
	} else {
		$('.b2c-banner').hide();
		$('.b2c-hotdeals').hide();		
	}
	//The shopping cart icon is a bag on the login page for some reason?
	$('.i-cart').removeClass('i-cart').addClass('fa').addClass('fa-shopping-cart');
	// On mobile, show favorite/wishlist urls to logged out users
	if ($(window).width() < 1200) {
		$('.add-to-links').each(function() {
			$(this).removeClass('visible-onhover');
		});
	}
	// Add favorites/wishlists
	if ( !$('.product-image-wrapper .add-to-links .fa-star').length){
			$('.product-image-wrapper .add-to-links').append('<li class="li-wishlist"> <a class="link-wishlist add-to-wishlist pop-up-refresh" href="/user/login/" style="display:block" title="Add To Wishlist" rel="nofollow"> <span class="icon icon-hover fa fa-star"></span> </a> </li> <li class="li-favorites"> <a class="link-favorites add-to-favorite pop-up-refresh" href="/user/login" style="display:block" title="Add To Favorites" rel="nofollow"> <span class="icon icon-hover fa fa-heart"></span> </a> </li>');
	}
	//  On mobile, show filter drop down
	if ($(window).width() < 960) {
		$('.mmenu-target-filter .block-title').append('<i class="fa fa-chevron-down"></i>');
		let clicked = false;
		$('.mmenu-target-filter .block-title').click(function() {
			if (clicked == false) {
				clicked = true;
				$('.mmenu-target-filter .block-content').slideDown();
				$('.block-title .fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
			} else {
				clicked = false;
				$('.mmenu-target-filter .block-content').slideUp();
				$('.block-title .fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}
		});
		// Add scroll to top at the end
	    //$('#filter_list_box').append('<div id="filters_scrolltop" style="text-align:center;text-decoration:underline">Scroll to Top</div>');  
	    
	    $(document).on("click","#filters_scrolltop",function() {
	    	$('#filter_list_box').scrollTop(0); 
	    });
	// Show filter dropdown on desktop
	} else {
		// Add chevron
		$('.module-category-filters dt').append('<i style="margin:0 5px;cursor:pointer" class="desktop-filter fa fa-chevron-down"></i>');
		// Add logic to hide filter options if none are selected
		$('dd .fa-square-o').closest('dd').hide();
		// Add on click acton
		$('.desktop-filter').click(function() {
			if ( $(this).parent().next().is(':visible') ) {
				$(this).parent().next().slideUp();
				$(this).removeClass('fa-chevron-up').addClass('fa-chevron-down');
			} else {
				$(this).parent().next().slideDown();
				$(this).removeClass('fa-chevron-down').addClass('fa-chevron-up');
			}
		})	
	}
	// Add favorites to header
	if (!$('.header .link-wishlist').length ) {
		$('.module-user-links .links').append('<li><a href="/user/login" rel="nofollow" class="link-wishlist" title="Add to Favorites">Wishlist</a></li>');
	}
	// Add favorite icon to category llisting when logged out 
	if (!$('.category-products-listing .add-to-favorite').length ) {
		$('.category-products-listing .add-to-links').append('<li class="favorites"> <a href="/user/login?redirectUrlHash=review-title" class="link-favorites add-to-favorite pop-up-refresh" rel="nofollow" title="Add To Favorites"> <span class="v-center"><span class="icon icon-hover fa fa-heart"></span></span> </a> </li>');
	}
	// Add wishlist/favorite links to product pages for non-logged in users
	if ( !$('.module-product-details .add-to-favourites').length ) {
		let afterthis = $('.module-product-details .add-cart-wrapper').parent();
		afterthis.after('<div class="grid12-12"><div class="action-box clearer"> <ul class="add-to-links addto-gaps-right"> <li class="wishlist"> <a href="/user/login?redirectUrlHash=review-title" class="feature feature-icon-hover first v-centered-content button add-to-wishlist pop-up-refresh" rel="nofollow" title="Add To Wishlist"> <span class="v-center"><span class="icon fa fa-star icon-color-productview"></span></span><span class="v-center span-title">Add To Wishlist</span> </a> </li> <li class="favorites"> <a href="/user/login?redirectUrlHash=review-title" class="feature feature-icon-hover first v-centered-content button add-to-favourites pop-up-refresh" rel="nofollow" title="Add To Favorites"> <span class="v-center"><span class="icon fa fa-heart icon-color-productview"></span></span><span class="v-center span-title"> Add To Favorites</span></a></li></ul></div></div>');
	}
	// Float right the grid options on category listing
	$('.sorter').parent().css('float','right');
	// Add link to login to leave review
	if ( $('.no-review-msg').length && !$('.reviewForm').length) {
		$('.no-review-msg').append(' <a href="/user/login?redirectUrlHash=review-title" style="text-decoration:underline">Login to leave review.</a>');
	}
	// Insert stock button on choosing of new variant products
	$(document).ajaxComplete(function(event, xhr, settings) {
		if ($(window).width() > 960) {
	        if(settings.url.includes("ajax/variants/variantDetails") && $('#stocklevel').length) {
	        	$(".gen-tabs .li_stocklevel").remove(); // added as per 161933000038225
	        	$(".product-type-data #stocklevel").css("display","none");
	        	var stocklevel_html = $('#stocklevel').html().replace("button btn-cart v-center btn-inline","");
				$('.tabs li:last-child').after('<li class="li_stocklevel">' + stocklevel_html + '</li>');
	        }
		}
	});
	// Move the check stock button next to the tabs
	if ($(window).width() > 960) {
		$(".product-type-data #stocklevel").css("display","none");
		if ( !$('.variants_con').length && $('#stocklevel').length ) {
			var stocklevel_html = $('#stocklevel').html().replace("button btn-cart v-center btn-inline","");
			$('.tabs li:last-child').after('<li class="li_stocklevel">' + stocklevel_html + '</li>');
		}
	}
	// Change nav menu colors
	switch ( getCategoryType(window.location.href) ) {
		case 'automotive':
			$('.desktop-nav-menu #nav').css('background','#f88d2b');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		case 'protective':
			$('.desktop-nav-menu #nav').css('background','#ce2326');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		case 'tools':
			$('.desktop-nav-menu #nav').css('background','#02528f');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		case 'equipment':
			$('.desktop-nav-menu #nav').css('background','#0483b6');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		case 'outdoors':
			$('.desktop-nav-menu #nav').css('background','#046A38');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		case 'paints':
			$('.desktop-nav-menu #nav').css('background','#fcd123');
			$('.nav-item .level-top').css('color','#050000');
			$('.nav-item.level0:hover>a').css('color','#050000');
			$('#nav .caret').css('border-top-color','#050000');
			break;
		case 'cycling':
			$('.desktop-nav-menu #nav').css('background','#050000');
			$('.nav-item .level-top').css('color','#fff');
			$('.nav-item.level0:hover>a').css('color','#fff');
			break;
		default:
			// Default nav hover
			$('.nav-item.level0:hover>a').css('color','#f88d2b');
	}
	function getCategoryType(url) {
		console.log("heyyyy");
		if ( url.includes('/automotive/') ) {
			return 'automotive';	
		}	
		if ( url.includes('/protective-equipment/') ) {
			return 'protective';	
		}
		if ( url.includes('/tools/') ) {
			return 'tools';	
		}	
		if ( url.includes('/equipment/') ) {
			return 'equipment';	
		}	
		if ( url.includes('/outdoor/') ) {
			return 'outdoors';	
		}
		if ( url.includes('/paints-and-abrasives/') ) {
			return 'paints';	
		}
		if ( url.includes('/cycling/') ) {
			return 'cycling';	
		}
	}
	 
	// Force scroll to top
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		document.body.scrollTop = 0; // For Safari
	// Is visible jquery function
	$.fn.isVisible = function() {
	    // Current distance from the top of the page
	    var windowScrollTopView = $(window).scrollTop();
	    // Current distance from the top of the page, plus the height of the window
	    var windowBottomView = windowScrollTopView + $(window).height();
	    // Element distance from top
	    var elemTop = $(this).offset().top;
	    // Element distance from top, plus the height of the element
	    var elemBottom = elemTop + 1;
	    return ((elemBottom <= windowBottomView) && (elemTop >= windowScrollTopView));
	}
	// Is sidebar long enough to justify floating to the bottom?
	function isSideBarLong() {
		var sidebar = $('.sidebar-wrapper').outerHeight();
		console.log("sidebar:" + sidebar);
		if (sidebar > 350) {
			return true;	
		} else {
			return false;	
		}
	}
	// Floating sidebar on category pages
	if ($('.cymot-category-sidebar').length > 0) {
		// Configure position of sidebar
		if ( $(window).width() >= 961) {
			// Desktop 
			$('.cymot-category-sidebar').css('min-height','100px');
			$('.cymot-category-sidebar .sidebar-wrapper').css('width',$('.cymot-category-sidebar').width() + 'px');
			$('.cymot-category-sidebar .sidebar-wrapper').css('position','fixed');
			console.log('isSideBarLong: ' + isSideBarLong());
			if (isSideBarLong()) {
				$(window).scroll(function (event) {
					if ( $('#footer').isVisible() ) {
						$('.cymot-category-sidebar .sidebar-wrapper').css("bottom",$('#footer').outerHeight() +'px');
						$('.cymot-category-sidebar .sidebar-wrapper').css('top','unset');
						$('.cymot-category-sidebar').css('top','unset');
					} else {
						$('.cymot-category-sidebar .sidebar-wrapper').css('bottom','unset');
						$('.cymot-category-sidebar .sidebar-wrapper').css('top',$('#header').outerHeight()+'px');
						$('.cymot-category-sidebar').css('top',$('#header').outerHeight() + 'px');
					}
				});
			}
		} else {
			// Mobile view
			$(window).scroll(function (event) {
				$('.cymot-category-sidebar').css('top',$('#header').height() + 'px');
			});
			$('.cymot-category-sidebar').css('top',$('#header').height() + 'px');
			$('.cymot-category-sidebar').css('width','100%');
			$('.cymot-category-sidebar').css('margin','0 auto');
			$('.cymot-category-sidebar').addClass('mobile-floating-cats');
			// pushes down the main conainter
			$('.main-container').css('margin-top',$('.cymot-category-sidebar').height()+'px');
		}
	}
	// Hack to ensure footer occupies all whitespace at the end of the page
	$(window).on("load", function() {
		const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - $('.footer-container').outerHeight();
		if (newh > 0) {
	    	$('.main-container').css('margin-bottom', newh +'px');
		}
	});
	// Change newsletter button
	if ($('.newsletter-button span').length > 0) {
		$('.newsletter-button span').html('Submit');
	}
	// If there are reviews reviews...
	if ($('.review-wrapper').length > 0) {
		var count = 0;
		// hide all reviews except the first one. 
		$('.review-wrapper').each(function() {
			if (count !== 0) {
				$(this).hide();	
			} else {
				$(this).slideDown();
				$(this).append('<div style="text-align:center;margin:10px auto;"><button class="button btn-inline more-reviews"><span style="padding:5px 30px;">More <i class="fa fa-chevron-down"></i></span></button></div>');	
			}
			count++;
		});
		// Slide down on click of "more"
		$(document).on('click', '.more-reviews', function(){
			$(this).hide();
			$('.review-wrapper').slideDown();
			$('#review-form').slideDown();
		});
		
	} else {
		// No reviews, so show form by default.
		$('#review-form').slideDown(); 
		$('#review-form').slideDown();
	}
	// Set the placeholder on the subscribe form input
	if ($('#news-letter-input_email').length > 0) {
    	$('#news-letter-input_email').attr('placeholder', 'Email Address');
    }
	// Add subheadin gbelow Featured Products
	let hotdeals_heading = $(".homepage-hotdeals .section-title");
	if (hotdeals_heading.text().includes("Featured Products")) {
		hotdeals_heading.after("<h4>Check out some of our favourite items!</h4>");	
	}
    // This is the on hover effect for the site category images just below the slider
    /*
    if ($(window).width() > 960) {
    	$(".cymot-home-sections").find('span').fadeIn();
    	$(".cymot-home-sections").find('.cymot-home-sections-bg').fadeIn();
    } else {
    	$(".cymot-home-sections").each(function() {
    		if ( $(this).is(':visible') ){
    			$(this).find('span').fadeIn();	
    		} else {
    			$(this).find('span').fadeOut();	
    		}
    	});
    	$(window).scroll(function (event) {
		    var scroll = $(window).scrollTop();
			// Eventually add functionality to show title upon scroll position
		});
    }
    */
    /*
    $(".cymot-home-sections").mouseenter(function() {
        $(".cymot-home-sections").find('span').fadeOut();
        $(".cymot-home-sections-bg").fadeOut();
        $(this).find('span').fadeIn();
        $(this).find('.cymot-home-sections-bg').fadeIn();
    });
    */
    // End hover effect

    var isScrolledIntoView = function(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    };

    /* floating header */
    var halfScreenSize = ($(document).width() * 0.5);
    $(window).scroll(function() {
        $this = $(this);
        var topSize = 120;
        if ($this.width() <= 960) {
            topSize = 36;
        }
        if ($this.scrollTop() >= topSize) {
            if (!$('#header').hasClass('floating')) {
                $('body').css('padding-top', $('.header-container').height());
                $('#header').addClass('floating');
                $('#header').slideDown('fast');
                //$('.item-min-cart').hide();
                //$('.item-user-links').hide();
                //$('.item-switch-customer').hide();
            }
        }
        // Here the header doesn't float
        if ($this.scrollTop() < topSize) {
            if ($('#header').hasClass('floating')) {
                $('body').attr('style', '');
                $('#header').removeClass('floating');
                $('#header').attr('style', '');
                //$('.item-min-cart').show();
                //$('.item-user-links').show();
                //$('.item-switch-customer').show();
            }
        }

        if ($('button.btn-cart').length > 0) {
            var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
            if (isVisibleOnScreen) {
                $('.add-cart-fixed').hide();
                $('.add-cart-fixed').width('50%');
            } else {
                if ($('.add-cart-fixed').width() === halfScreenSize) {
                    $('.add-cart-fixed').show().animate({
                        width: '100%'
                    });
                }
            }
        }
    });

    if ($("#mini-cart").length > 0) {
        $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
    }

    $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");

    if ($(".variant-option").length > 0) {
        if ($(".variant-option .swatches-con .swatch img").length > 0) {
            $(".variant-option .swatches-con").css('display', 'block');
        }
    }
	/* 161933000032810
    $(window).on('resize', onResize);
	
    $(document).ready(function() {
        onResize();
    });
    

    var onResize = function() {
        if ($(window).width() < 960) {
            $(".nav-search .form-search .button.button-search").on("click", function(e) {
                if (!$(".nav-search").hasClass("fly-out")) {
                    e.preventDefault();
                    $(".nav-search").addClass("fly-out");
                    $(".nav-search .form-search .input-text").focus();
                    $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
                }
            });
        }
    };
    */

    $(document).on('click', '.nav-search .button-close', function(e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });
});